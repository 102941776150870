import { boolean } from 'boolean';
import { noop } from 'lodash';
import { usePathname } from 'next/navigation';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import ReactWalkme from 'react-walkme';

import ENV from '~/constants/env';
import USER_TYPES from '~/constants/userTypes';
import { WalkMeSetUserData } from '~/helpers/walkme/WalkMeInsights';
import useActiveUser from '~/hooks/useActiveUser';
import useLogger from '~/hooks/useLogger';
import { ILogger } from '~/types';

export interface IWalkMeContextData {
  isMenuAvailable: boolean;
  toggleMenu: () => void;
}

export const WalkMeContext = createContext<IWalkMeContextData>({
  isMenuAvailable: false,
} as IWalkMeContextData);

const WalkMeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const logger: ILogger = useLogger();
  const maintenanceMode = boolean(ENV.MAINTENANCE_MODE);
  const [isMenuAvailable, setIsMenuAvailable] = useState(false);
  const [walkMeStarted, setWalkMeStarted] = useState(false);

  const pathname = usePathname();
  const activeUser = useActiveUser();
  const isUserLoggedIn = Boolean(activeUser);

  useEffect(() => {
    if (!activeUser?.uid) return;

    window.walkme_ready = (): void => {
      setIsMenuAvailable(true);
    };

    // This is required for the WalkMe Menu to start correctly
    WalkMeSetUserData({
      role: activeUser?.type === USER_TYPES.LDAP ? 'ldap_user' : undefined,
      uid: activeUser?.uid,
    });
  }, [activeUser]);

  useEffect(() => {
    if (!pathname?.includes('login')) {
      ReactWalkme.getWalkMeAPI();
    }
  }, [pathname]);

  useEffect((): void => {
    const initWalkme = (): void => {
      const walkmeInstance = ReactWalkme.walkme();

      if (walkmeInstance.error) {
        const walkmeId = ENV.WALKME_ID as string;
        const walkmeTest = boolean(ENV.WALKME_TEST);
        const walkmeDebug = boolean(ENV.WALKME_DEBUG);

        ReactWalkme.initialize(walkmeId, walkmeTest, walkmeDebug);
      }
    };

    if (!maintenanceMode && !walkMeStarted && isUserLoggedIn) {
      setWalkMeStarted(true);
      initWalkme();
    }
  }, [logger, maintenanceMode, walkMeStarted, isUserLoggedIn]);

  const toggleMenu = useMemo(() => (isMenuAvailable ? window?.WalkMePlayerAPI?.toggleMenu : noop), [isMenuAvailable]);

  const providerValue = useMemo(
    () =>
      ({
        isMenuAvailable,
        toggleMenu,
      }) as IWalkMeContextData,
    [isMenuAvailable, toggleMenu],
  );

  return <WalkMeContext.Provider value={providerValue}>{children}</WalkMeContext.Provider>;
};

export default WalkMeProvider;
